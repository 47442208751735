<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul
      v-if="isAuthenticated"
      class="nav navbar-nav d-xl-none"
    >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav
      v-if="isAuthenticated"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user == null ? "" : user.alias }}
            </p>
            <span
              v-if="userIdentifier != null && userIdentifier.length > 0"
              class="user-status text-muted"
            >
              {{ userIdentifier.toUpperCase() }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :text="
              user == null || user.alias == null || user.alias.length === 0
                ? ''
                : user.alias.charAt(0)
            "
            class="badge-minimal"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({ name: 'profile' })"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="MailIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Inbox</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="CheckSquareIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Task</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="MessageSquareIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Chat</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-divider />-->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$auth.logout({ returnTo: logoutRedirect })"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-navbar-nav
      v-else
      class="nav align-items-center ml-auto"
    >
      <b-button
        class="mr-1"
        pill
        variant="outline-primary"
        @click="login"
      >
        Login
      </b-button>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  BButton,
  BButtonGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BButton,
  
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userIdentifier: null,
      isAuthenticated: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['app/dashboard']
    },
    logoutRedirect() {
      const { location } = window
      return `${location.protocol}//${location.host}/launchpad`
    },
  },
  async mounted() {
    console.debug('mounted')
    this.$store.dispatch('app/fetchDashboard')
    console.debug('before user refresh')
    const authUser = await this.$auth.refreshUser()
      .catch(() => { console.debug('error refreshing') })
    console.debug('after user refresth')
    if (authUser != null && authUser.sub != null && authUser.sub.length > 5) {
      this.userIdentifier = authUser.sub.slice(authUser.sub.length - 6)
    }
    if (authUser != null) {
      this.isAuthenticated = true
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    signUp() {
      this.$auth.loginWithRedirect({
        screen_hint: 'signup',
      })
    },
  },
}
</script>
