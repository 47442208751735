export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Virtual Groups',
    route: 'virtual-groups',
    icon: 'UsersIcon',
  },
]
